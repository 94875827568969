<template>
  <management-container type="settlement"></management-container>
</template>

<script>
import ManagementContainer from "./layout/management-container.vue";
export default {
  name: "PointSettingSettleMent",
  components: {
    ManagementContainer
  }
};
</script>

<style>
</style>